@import url('~assets/css/bootstrap.min.css');
@import url('~assets/css/themify-icons.css');

/*@import url('https://use.fontawesome.com/releases/v5.0.6/js/fontawesome.js');*/


/*@import url('https://fonts.googleapis.com/css?family=Muli:300,400,600,700,800');*/

@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@font-face {
    font-family: 'Gotham-Book';
    src: url(assets/fonts/GothamBook.eot);
    src: url(assets/fonts/GothamBook.eot) format('embedded-opentype'), url(assets/fonts/GothamBook.woff) format('woff'), url(assets/fonts/GothamBook.ttf) format('truetype'), url(assets/fonts/GothamBook.svg#svgFontName) format('svg');
}

@font-face {
    font-family: 'Gotham-Medium';
    src: url(assets/fonts/GothamMedium.eot);
    src: url(assets/fonts/GothamMedium.eot) format('embedded-opentype'), url(assets/fonts/GothamMedium.woff) format('woff'), url(assets/fonts/GothamMedium.ttf) format('truetype'), url(assets/fonts/GothamMedium.svg#svgFontName) format('svg');
}

.form-control:focus {
    box-shadow: none;
}

:focus {
    outline: none;
}

body {
    font-family: 'Gotham-Book', 'Muli', sans-serif;
    background-color: #f9f9f9;
    padding-top: 64px;
    font-size: 12px;
    line-height: 1.33;
    font-weight: 300;
    color: #646464;
    /* background-image: url("../assets/images/header-bg.png");
    background-size: cover; */
}

.font-GMLbl {
    font-family: 'Gotham-Medium', 'Muli', sans-serif;
    color: rgba(100, 100, 100, 0.7);
}

.card-h1 {
    font-size: 48px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.06;
    letter-spacing: 2.8px;
    text-align: left;
    color: #646464;
}

h5 {
    font-size: 1rem;
}

h6 {
    font-size: 0.8rem;
}

.mat-button-toggle {
    font-family: "Gotham-Book", Roboto, "Helvetica Neue", sans-serif;
}

.payments h1 {
    font-size: 38px;
    letter-spacing: 0.04px;
    line-height: 40px;
}

.text-red {
    color: #e34e38;
}

.text-bluish {
    color: #12afec;
}

.text-blue {
    color: #12afec !important;
}

.text-bold-blue {
    color: #12afec;
    font-size: 18px;
    ;
}

.text-dark-gray {
    color: #737373 !important;
}

.progress-bar {
    height: 0.3rem;
}
.progress {
    height: 0.3rem;
}

.socialLinkMenu button.mat-menu-item {
    height: 30px;
    line-height: 30px;
}

.socialLinkMenu .mat-menu-content:not(:empty) {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 2px;
}

.socialLinkMenu .mat-menu-item:hover:not([disabled]) {
    opacity: 0.9 !important;
}

.facebook-bg,
.facebook-bg:hover {
    background: #3b5999 !important;
    color: #fff !important;
}

.twitter-bg,
.twitter-bg:hover {
    background: #55acee !important;
    color: #fff !important;
}

.linkedin-bg,
.linkedin-bg:hover {
    background: #0077B5 !important;
    color: #fff !important;
}

.gplus-bg,
.gplus-bg:hover {
    background: #dd4b39 !important;
    color: #fff !important;
}

.youtube-bg,
.youtube-bg:hover {
    background: #cd201f !important;
    color: #fff !important;
}

.hidden {
    display: none !important;
}

.mat-drawer.mat-drawer-side {
    z-index: 2;
    position: fixed;
    overflow-y: auto;
}

.font-12 {
    font-size: 12px;
}

.font-18 {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.64px;
}


/*.......................margin class ....................*/


/*margin all side*/

.m-a-0 {
    margin: 0px !important;
}

.m-a-5 {
    margin: 5px !important;
}

.m-a-10 {
    margin: 10px !important;
}

.m-a-15 {
    margin: 15px !important;
}

.m-a-20 {
    margin: 20px !important;
}

.m-a-25 {
    margin: 25px !important;
}

.m-a-30 {
    margin: 30px !important;
}

.m-a-35 {
    margin: 35px !important;
}

.m-a-40 {
    margin: 40px !important;
}

.m-a-45 {
    margin: 45px !important;
}

.m-a-50 {
    margin: 50px !important;
}


/*margin top*/

.m-t-0 {
    margin-top: 0px !important;
}

.m-t-2 {
    margin-top: 2px !important;
}

.m-t-5 {
    margin-top: 5px !important;
}

.m-t-7 {
    margin-top: 7px !important;
}

.m-t-10 {
    margin-top: 10px !important;
}

.m-t-15 {
    margin-top: 15px !important;
}

.m-t-20 {
    margin-top: 20px !important;
}

.m-t-25 {
    margin-top: 25px !important;
}

.m-t-30 {
    margin-top: 30px !important;
}

.m-t-35 {
    margin-top: 35px !important;
}

.m-t-40 {
    margin-top: 40px !important;
}

.m-t-45 {
    margin-top: 45px !important;
}

.m-t-50 {
    margin-top: 50px !important;
}

.m-t-55 {
    margin-top: 55px !important;
}

.m-t-60 {
    margin-top: 60px !important;
}


/*margin bottom*/

.m-b-0 {
    margin-bottom: 0px !important;
}

.m-b-5 {
    margin-bottom: 5px !important;
}

.m-b-10 {
    margin-bottom: 10px !important;
}

.m-b-15 {
    margin-bottom: 15px !important;
}

.m-b-20 {
    margin-bottom: 20px !important;
}

.m-b-25 {
    margin-bottom: 25px !important;
}

.m-b-30 {
    margin-bottom: 30px !important;
}

.m-b-35 {
    margin-bottom: 35px !important;
}

.m-b-40 {
    margin-bottom: 40px !important;
}

.m-b-45 {
    margin-bottom: 45px !important;
}

.m-b-50 {
    margin-bottom: 50px !important;
}


/*margin left*/

.m-l-0 {
    margin-left: 0px !important;
}

.m-l-5 {
    margin-left: 5px !important;
}

.m-l-10 {
    margin-left: 10px !important;
}

.m-l-15 {
    margin-left: 15px !important;
}

.m-l-20 {
    margin-left: 20px !important;
}

.m-l-25 {
    margin-left: 25px !important;
}

.m-l-30 {
    margin-left: 30px !important;
}

.m-l-35 {
    margin-left: 35px !important;
}

.m-l-40 {
    margin-left: 40px !important;
}

.m-l-45 {
    margin-left: 45px !important;
}

.m-l-50 {
    margin-left: 50px !important;
}


/*margin right*/

.m-r-0 {
    margin-right: 0px !important;
}

.m-r-5 {
    margin-right: 5px !important;
}

.m-r-10 {
    margin-right: 10px !important;
}

.m-r-15 {
    margin-right: 15px !important;
}

.m-r-20 {
    margin-right: 20px !important;
}

.m-r-25 {
    margin-right: 25px !important;
}

.m-r-30 {
    margin-right: 30px !important;
}

.m-r-35 {
    margin-right: 35px !important;
}

.m-r-40 {
    margin-right: 40px !important;
}

.m-r-45 {
    margin-right: 45px !important;
}

.m-r-50 {
    margin-right: 50px !important;
}


/*.......................padding class ....................*/


/*padding all side*/

.p-a-0 {
    padding: 0px !important;
}

.p-a-5 {
    padding: 5px !important;
}

.p-a-10 {
    padding: 10px !important;
}

.p-a-15 {
    padding: 15px !important;
}

.p-a-20 {
    padding: 20px !important;
}

.p-a-25 {
    padding: 25px !important;
}

.p-a-30 {
    padding: 30px !important;
}

.p-a-35 {
    padding: 35px !important;
}

.p-a-40 {
    padding: 40px !important;
}

.p-a-45 {
    padding: 45px !important;
}

.p-a-50 {
    padding: 50px !important;
}


/*padding top*/

.p-t-5 {
    padding-top: 5px !important;
}

.p-t-10 {
    padding-top: 10px !important;
}

.p-t-15 {
    padding-top: 15px !important;
}

.p-t-20 {
    padding-top: 20px !important;
}

.p-t-25 {
    padding-top: 25px !important;
}

.p-t-30 {
    padding-top: 30px !important;
}

.p-t-35 {
    padding-top: 35px !important;
}

.p-t-40 {
    padding-top: 40px !important;
}

.p-t-45 {
    padding-top: 45px !important;
}

.p-t-50 {
    padding-top: 50px !important;
}


/*padding bottom*/

.p-b-0 {
    padding-bottom: 0px !important;
}

.p-b-5 {
    padding-bottom: 5px !important;
}

.p-b-10 {
    padding-bottom: 10px !important;
}

.p-b-15 {
    padding-bottom: 15px !important;
}

.p-b-20 {
    padding-bottom: 20px !important;
}

.p-b-25 {
    padding-bottom: 25px !important;
}

.p-b-30 {
    padding-bottom: 30px !important;
}

.p-b-35 {
    padding-bottom: 35px !important;
}

.p-b-40 {
    padding-bottom: 40px !important;
}

.p-b-45 {
    padding-bottom: 45px !important;
}

.p-b-50 {
    padding-bottom: 50px !important;
}


/*padding left*/

.p-l-0 {
    padding-left: 0px !important;
}

.p-l-5 {
    padding-left: 5px !important;
}

.p-l-10 {
    padding-left: 10px !important;
}

.p-l-15 {
    padding-left: 15px !important;
}
.p-l-17 {
    padding-left: 17px !important;
}
.p-l-20 {
    padding-left: 20px !important;
}

.p-l-25 {
    padding-left: 25px !important;
}

.p-l-30 {
    padding-left: 30px !important;
}

.p-l-35 {
    padding-left: 35px !important;
}

.p-l-40 {
    padding-left: 40px !important;
}

.p-l-45 {
    padding-left: 45px !important;
}

.p-l-50 {
    padding-left: 50px !important;
}
.p-l-46 {
    padding-left: 46px !important;
}


/*padding right*/

.p-r-0 {
    padding-right: 0px !important;
}

.p-r-5 {
    padding-right: 5px !important;
}

.p-r-10 {
    padding-right: 10px !important;
}

.p-r-15 {
    padding-right: 15px !important;
}

.p-r-20 {
    padding-right: 20px !important;
}

.p-r-25 {
    padding-right: 25px !important;
}

.p-r-30 {
    padding-right: 30px !important;
}

.p-r-35 {
    padding-right: 35px !important;
}

.p-r-40 {
    padding-right: 40px !important;
}

.p-r-45 {
    padding-right: 45px !important;
}

.p-r-50 {
    padding-right: 50px !important;
}

table.highlight>tbody>tr {
    border-left: 5px solid transparent;
    border-bottom: 1px solid #dedede;
}

table.highlight>tbody>tr:hover {
    background-color: #f4fbfe;
    border-left: 5px solid #12afec;
}

.border-b-1 {
    border-bottom: 1px solid #e5e4e4;
    /* width: 95%; */
}

.border-b-4 {
    border-bottom: 4px solid #12afec;
}

.border-b-blank {
    border-bottom: 4px solid transparent;
}

.danger-bg,
.mat-danger-bg {
    background-color: #e34e38 !important;
}

.success-bg,
.mat-success-bg {
    background-color: #7ed321 !important;
}

.warning-bg,
.mat-warning-bg {
    background-color: #fbae17 !important;
}

.border-right-1 {
    border-right: 1px solid #ababab;
}

.border-left-1 {
    border-left: 1px solid #ababab;
}

.text-danger {
    color: #e34e38 !important;
    font-size: 13px;
}

.text-success {
    color: #7ed321 !important;
    font-size: 13px;
}

.text-warning {
    color: #fbae17 !important;
    font-size: 13px;
}
text-blue {
  color: #12afec;
}
.text-gray {
    color: rgba(100, 100, 100, 0.72);
    letter-spacing: 0.64px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}
.text-gray-m-16 {
  color: rgba(100, 100, 100, 0.72);
  letter-spacing: 0.64px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.text-gray-r-16 {
  color: rgba(100, 100, 100, 0.72);
  letter-spacing: 0.64px;
  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
}
.map_layoutG {
    height: 100%;
    width: 100%;
}

.card-h4{
    font-size: 35px!important;
}

.map_layoutG#map {
    border: rgb(187, 187, 187) 1px solid
}

.leftMenu::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
    border-radius: 0px;
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.44, #a73223), color-stop(0.72, #a73223), color-stop(0.86, #a73223));
}

.leftMenu::-webkit-scrollbar,
body::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
}

.leftMenu::-webkit-scrollbar-track,
body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
    border-radius: 0px;
}


/* a{color:#a90f2d;} */


/* a:hover{color:#3f51b5; text-decoration:none;} */

a {
    text-decoration: underline;
}

.mat-tab-label-container {
    padding-right: 15px;
}

.siteFont,
.siteLoading,
.mat-tab-group,
.mat-form-field-empty.mat-form-field-label,
.mat-checkbox-layout,
.mat-table,
.mat-button,
.mat-fab,
.mat-icon-button,
.mat-mini-fab,
.mat-raised-button,
.mat-radio-button,
.mat-expansion-panel-content,
.mat-expansion-panel-header,
.mat-stepper-horizontal,
.mat-stepper-vertical,
.mat-form-field {
    font-family: 'Gotham-Book', 'Muli', sans-serif !important;
}

.mat-form-field-empty.mat-form-field-label,
.mat-checkbox-layout {
    font-size: 14px;
}

.mat-form-field {
    margin-left: 0 !important;
}

.mat-calendar-body-selected {
    background-color: #12afec !important;
    color: #fff;
}

.fontWeight600 {
    font-weight: 600;
}

.siteLoading {
    font-weight: 600;
    padding-top: 10px;
}

ul {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;
}

.mLft5 {
    margin-left: 5px !important;
}

.mBtm10 {
    margin-bottom: 10px;
}

.mTop10 {
    margin-top: 10px;
}

.mTop20 {
    margin-top: 20px;
}

.mTop15 {
    margin-top: 15px;
}

.mBtm15 {
    margin-bottom: 15px;
}

.mBtm25 {
    margin-bottom: 25px;
}

.pBtm0 {
    padding-bottom: 0 !important;
}

.pBtm10 {
    padding-bottom: 10px !important;
}

.pTop10 {
    padding-top: 10px !important;
}

.mBtm0 {
    margin-bottom: 0px !important;
}

.pL0 {
    padding-left: 0px !important;
}

.pLR0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.header {
    /* height: 64px;
    padding: 5px 20px 90px 20px; */
    height: 0;
    padding: 4px 20px 74px 20px;;
    /* background-color: #f5ec48; */
    /* box-shadow: 0 3px 5px -1px rgba(0,0,0,.2),0 6px 10px 0 rgba(0,0,0,.14),0 1px 18px 0 rgba(0,0,0,.12); */
    position: fixed;
    /* z-index: 1028; */
    z-index: 9;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    background-image: url(assets/img/header-bg.svg);
    background-size: auto 100%;
    background-position: 0px 0px;
}

.mat-drawer {
    background-color: #e34e38 !important;
    color: rgba(255, 255, 255, 0.87);
}

.header .mat-icon {
    color: #12afec !important;
}

.rightUserSec {
    float: right;
    margin-right: 15px;
}

.colorOrg {
    background-color: #df4000;
}

.colorGrey {
    background-color: #3b3954;
}

.formWhiteBox {
    text-align: center;
    color: #444;
    /* padding: 3em; */
}

.logo {
    /* background: #fff; */
    padding: 10px;
    /* width: 180px; */
    border-radius: 4px;
    margin: 0 17px;
    margin-top: 2px;
    float: left;
}

.sign-logo {
    padding-bottom: 20px;
}

.sign-logo img {
    max-width: 180px;
}

.logo img {
    max-width: 100%;
    width: 156px;
}

.userProfileMenuBox {
    min-width: 150px !important;
    /* margin-top: 52px !important; */
    max-height: inherit !important;
}

.formWhiteBox h1 {
    font-size: 20px;
    font-weight: 800;
    padding-bottom: 15px;
    text-transform: uppercase;
    color: #a90f2d;
    margin: 0;
}

.topSettingsIcon {
    font-size: 24px;
    line-height: 18px !important;
}

.notificationSection {
    float: left;
    margin-right: 15px;
    position: relative;
    margin-top: 2px;
}

.notificationSection .icon_bell .iconBell,
.userIcon {
    font-size: 24px !important;
    color: #12afec !important;
}

.notificationSection .badge {
    position: absolute;
    font-size: 10px;
    top: 5px;
    background: red;
    z-index: 9;
    right: 5px;
}

.lv-header {
    text-align: center;
    padding: 4px 10px 13px;
    line-height: 100%;
    text-transform: uppercase;
    border-bottom: 1px solid #F0F0F0;
    font-weight: 700;
    color: #a90f2d;
    margin-bottom: 0px;
}

.listData {
    padding: 10px;
    width: 100%;
    display: inline-block;
    border-bottom: 1px solid #eee;
}

.img {
    float: left;
}

.img img {
    border-radius: 100%;
    width: 50px;
    height: 50px;
}

.imgSqu img {
    width: 67px;
    height: 67px;
    border-radius: 0;
}

.textSec {
    float: left;
    padding-left: 12px;
    width: calc(100% - 50px);
}

.lv-title {
    font-weight: 700;
}

.lv-small {
    font-size: 12px;
    color: #999;
}

.lv-footer {
    text-align: center;
    display: inline-block;
    width: 100%;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: .5px;
}

.notificationMenuBox {
    /* margin-top: 53px; */
    min-width: 180px !important;
}

.form-content {
    padding: 20px 0 5px 0;
}

.login-with-gw {
  background-color: #12afec !important;
    /* background-color: #f5f5f5;
	background-image: url("./assets/img/gplus-icon.png");
	background-repeat: no-repeat;
	border: 1px solid #ccc;
	border-radius: 3px;
	color: #333;
	cursor: pointer;
	display: inline-block;
	font-size: 16px;
	font-weight: 400;
	margin: 0; */
    padding: 8px 20px;
    /* text-decoration: none;
	vertical-align: baseline;
	white-space: nowrap;
	text-decoration:none; */
    width: 100%;
    max-width: 350px;
    background-position: center left 10px;
}

.login-with-google {
    background-color: #f5f5f5;
    background-image: url("./assets/img/gplus-icon.png");
    background-repeat: no-repeat;
    border: 1px solid #ccc;
    border-radius: 3px;
    color: #333;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 8px 20px;
    text-decoration: none;
    vertical-align: baseline;
    white-space: nowrap;
    text-decoration: none;
    width: 100%;
    max-width: 450px;
    background-position: center left 10px;
}

.login-with-google:hover {
    background-color: #e9e9e9;
    border-color: #999;
    text-decoration: none;
    color: #222;
}

.or {
    margin-top: 20px;
}

.loginMainDiv,
.whiteFormShodow {
    background-color: #fff;
    padding: 20px 30px;
    margin-bottom: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 2px rgba(10, 16, 20, .24), 0 0 2px rgba(10, 16, 20, .12);
    box-sizing: border-box;
    transition: all .3s ease-in;
    position: relative;
}

.signup-link {
    text-align: left;
    display: inline-block;
    width: 100%;
    padding: 7px 0px 4px 0;
    border-top: 1px solid #ddd;
}


/*.leftMenu {
    width: 236px;
    position: fixed;
    top: 63px;
    left: 0;
	height:100%;
	 box-shadow: 3px 0 6px rgba(0,0,0,.24);
	-webkit-transition:all linear 0.5s;
	transition:all linear 0.5s;
	background-color: #fff;
}*/


/* .leftMenu {
    width: 270px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 100px;
    border: 8px solid white;
    border-image-source: url(assets/img/blr.png);
    border-image-slice: 20 20;
    border-image-repeat: round;
    padding-bottom: 100px;
} */

.mat-accordion.ng-star-inserted {
    display: block;
    width: 100%;
    min-height: calc(100vh - 400px);
}

.leftMenu {
    width: 270px;
    height: calc(100vh - 0px);
    overflow-x: hidden;
    padding-top: 85px;
    border: 8px solid white;
    border-image-source: url(assets/img/blr.png);
    border-image-slice: 20 20;
    border-image-repeat: repeat;
}


.navGroups li {
    text-transform: capitalize;
    border-bottom: 0px solid rgba(0, 0, 0, .08);
}

.navGroups li a {
    color: rgba(255, 255, 255, 0.85);
    font-family: 'Gotham-Book', 'Muli', sans-serif;
    display: block;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.8px;
    line-height: 18px;
    text-decoration: none;
    transition: all .3s;
    padding: 14px 30px;
    position: relative;
    cursor: pointer;
}


/* .navGroups li a.active {color: #f5ec48;} */

.navGroups li a.active,
.navGroups li a:hover,
.navGroups li a.active .mat-expansion-panel-header,
.navGroups li a.active .dropDownMenu .mat-expansion-panel-header-title,
.navGroups li a:hover i,
.navGroups li a.active i {
    background: #a73223;
    color: #f5ec48;
    background-image: url(assets/img/active-menu.png);
    background-size: cover;
    background-repeat: repeat-x;
}

.leftSubmenu li a:active,
.leftSubmenu li a:hover {
    background: transparent !important;
    color: #f5ec48;
    background-image: none !important;
}

.leftSubmenu li a.active .dropDownMenu .mat-expansion-panel-header-title {
    color: #f5ec48 !important;
}

.navGroups li a i {
    font-size: 16px;
    padding: 2px;
    margin-right: 0px;
    color: rgba(0, 0, 0, .54);
    width: 30px;
    display: inline-block;
    height: 30px;
    text-align: center;
}

.wrapper .mainContainer {
    /*width: calc(100% - 280px);
    margin-left: 260px;
    padding-top: 0px;
	padding: 1em 2em;*/
    /* padding: .5em 1.5em; */
    /* padding: 4.5em 0.5em 0.5em 0.5em; */
    padding: 2em 0.5em 0.5em 0.5em;
    display: inline-block;
    width: 100%;
    min-height: calc(100vh - 64px);
    /* overflow: hidden; */
}

.flag {
    float: left;
    margin-right: 20px !important;
    width: 28px !important;
}

.flag img {
    max-width: 100%;
    vertical-align: inherit;
}


/* .headerUserSec{ margin-top:50px;} */

.profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 1px solid #fff;
    overflow: hidden;
}

.profile-pic img {
    max-width: 100%;
    vertical-align: initial;
}

.headerUserSec {
    max-width: 230px;
}

.headerUserSec ul.dropdown-user {
    padding: 0px;
}

.headerUserSec ul.dropdown-user li .dw-user-box {
    padding: 10px 15px;
}

.headerUserSec ul.dropdown-user li .dw-user-box .u-img {
    width: 70px;
    display: inline-block;
    vertical-align: top;
}

.headerUserSec ul.dropdown-user li .dw-user-box .u-img img {
    width: 100%;
    border-radius: 5px;
}

.headerUserSec ul.dropdown-user li .dw-user-box .u-text {
    display: inline-block;
    padding-left: 10px;
}

.headerUserSec ul.dropdown-user li .dw-user-box .u-text h4 {
    font-size: 18px;
    font-weight: 700;
    color: #333;
}

.headerUserSec ul.dropdown-user li.divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: rgba(120, 130, 140, 0.13);
}

.headerUserSec ul.dropdown-user li {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.headerUserSec ul.dropdown-user li a {
    padding: 9px 15px;
    display: block;
    color: #333;
    cursor: pointer;
}

.headerUserSec ul.dropdown-user li a:hover {
    background: #f2f4f8;
    color: #1976d2;
    text-decoration: none;
}

.headerUserSec ul.dropdown-user li a i {
    margin-right: 12px;
    font-size: 18px;
    margin-top: 2px;
    float: left;
}

.leftSubMenu li a {
    padding-left: 43px;
}

.billAdd {
    font-weight: 700;
    text-align: left;
    width: 100%;
    padding-top: 20px;
    text-decoration: underline;
}

.radiusTopNone {
    border-radius: 0px 0 8px 8px;
}

.actionBtnGroup .mat-mini-fab .mat-button-wrapper {
    font-size: 18px;
    padding: 0px 0;
}

.mat-horizontal-stepper-header {
    height: 50px !important;
    /* padding: 0 20px !important; */
    padding: 0 16px !important;
}

.mat-horizontal-stepper-header-container {
    margin-bottom: 10px;
    /*border: 0;
    background-color: #fff;
    padding: 5px 10px;
    margin-bottom: 25px;
    border-radius: 4px;
    box-shadow: 0 2px 2px rgba(10,16,20,.24), 0 0 2px rgba(10,16,20,.12);
    box-sizing: border-box;
    transition: all .3s ease-in;*/
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
    padding: 1px;
}

.mat-horizontal-content-container {
    padding: 0 !important;
    border: 0;
}

.border-all-side {
    border: 1px solid #ccc;
}

.whiteFormShodow h1 {
    font-size: 20px;
    font-weight: 800;
    padding-bottom: 15px;
    text-transform: uppercase;
    color: #a90f2d;
}

.mBtm10 {
    margin-bottom: 10px !important;
}

.mat-horizontal-stepper-content {
    overflow: initial !important;
}

.mat-horizontal-content-container {
    overflow: initial !important;
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
    overflow: hidden;
}

.mat-stepper-horizontal {
    background-color: transparent !important;
}

.gap-btn button {
    margin: 0 5px;
}

.status2Radio {
    padding: 10px 0;
}

.status2Radio .lBl {
    padding-right: 5px;
}

.status2Radio.radio-btn label {
    margin-bottom: 0;
    vertical-align: middle;
}

.status2Radio .mat-radio-label {
    margin-left: 4px;
}

.status2Radio .mat-radio-label-content {
    padding-left: 5px !important;
}
.mat-radio-label{
    word-break: break-all;
    white-space: pre-wrap !important;
}
.status2Radio.radio-btn .mat-radio-button {
    margin-left: 20px !important;
    margin-top: 0px !important;
    vertical-align: middle;
}

.header-strip {
    border-radius: 4px;
    box-shadow: 0 2px 2px rgba(10, 16, 20, .24), 0 0 2px rgba(10, 16, 20, .12);
    box-sizing: border-box;
    background: #fff;
    padding: 10px 15px;
    margin-bottom: 15px;
    margin-top: 15px;
    border-radius: 4px;
}

.header-strip h4 {
    margin-top: 2px;
    margin-bottom: 0;
}

.formWhiteBox .mat-tab-body-content {
    padding: 0 1px;
}

.example-container {
    width: 100% !important;
    height: auto !important;
    min-height: calc(100vh - 80px);
    border: none !important;
}

.example-sidenav {
    padding: 0 !important;
    box-shadow: 3px 0 6px rgba(0, 0, 0, .24);
}


/*.leftToggleMenu {
    position: absolute !important;
    top: 12px;
    z-index: 9999;
    left: 230px;
    font-size: 22px;
    color: #fff;
    background-color: #00adee;
    border: 1px solid #fff;
    border-radius: 100%;
    width: 40px;
    height: 40px;
}*/

.leftToggleMenu {
    position: fixed;
    top: 12px;
    z-index: 10;
    left: 5px;
    font-size: 24px;
    color: #12afec;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.leftToggleMenu:hover,
.leftToggleMenu:focus,
.leftToggleMenu:active {
    outline: none;
}

.mat-drawer-container {
    background-color: #f9f9f9 !important;
    color: inherit !important;
}

.white-bg {
    background: #ffffff !important;
}

.mat-expansion-panel-header-title {
    color: #333 !important;
    /* font-size: 16px;
    font-weight: 600; */
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 0.8px;
    line-height: 21px;
}

.mat-drawer.mat-drawer-side .mat-expansion-panel-header-title {
    /* font-size: 18px; */
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.8px;
    line-height: 24px;
}

.mat-expanded .mat-expansion-panel-header-title {
    color: #a90f2d !important;
}

.width60-Center {
    max-width: 60px;
    text-align: center;
}

.width50 {
    max-width: 50px;
}

.width100 {
    max-width: 100px;
}

.width150 {
    max-width: 150px;
}

.width200 {
    max-width: 200px;
    min-width: 200px;
}
.widthm275 {
  min-width: 270px;
}
.widthm250 {
  min-width: 250px;
}
.widthm200 {
  min-width: 200px;
}
.widthm150 {
  min-width: 150px;
}
.width120 {
    max-width: 120px;
}

.width-100px {
    width: 100px !important;
}

.width-150 {
    width: 150px !important;
}

.tblSubHeading {
    border-top: none;
}

.tblSubHeading h4 {
    font-size: 16px;
    font-weight: 800;
}

.tableMinWidth1000,
.tableScroll,
.largScrollTbl,
.tableMinWidth1024,
.tableMinWidth2600,
.tableMinWidth3000 {
    overflow: auto;
    width: 100%;
}

mat-table {
    min-width: 800px;
}

.tableScroll .mat-table {
    min-width: 800px;
}

.largScrollTbl .mat-table {
    min-width: 1800px;
}

.tableMinWidth1024 .mat-table {
    min-width: 800px;
}

.tableMinWidth2600 .mat-table {
    min-width: 2600px;
}

.tableMinWidth3000 .mat-table {
    min-width: 3000px;
}

.mat-expansion-panel-header-description {
    text-align: right;
    display: inline-block !important;
}

.tableRedTxtSubhead {
    color: #a90f2d !important;
    font-weight: 600;
    background: #f5f5f5;
}

.pageSubMenus {
    /* float: left; */
    width: 230px;
    background-color: #fff;
    box-shadow: 3px 0 6px rgba(0, 0, 0, .24);
    margin: -7px 20px 0px -22px;
    position: absolute;
    height: 100%;
}

.pageSubMenus .submenu_heading {
    font-weight: 600;
    padding: 10px 15px 10px 15px;
    margin-bottom: 0;
    background: rgba(0, 0, 0, .32);
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: .5px;
}


/* .rightMinSection {float: left; width: calc(100% - 230px); margin-left: 230px;} */

.rightMinSection {
    /* margin-left: 230px; */
}

.modal,
.modal-backdrop {
    z-index: 99;
}

.smlLabel {
    font-size: 11px;
    color: #777;
    display: inline-block;
    width: 100%;
    padding-bottom: 5px;
}

.aLabel {
    font-weight: bold;
}

.bold {
    font-weight: bold;
}

.boldGrayTitle {
    font-weight: 600;
    color: #646464;
}


/* Structure */

.example-header,
.tableSearchBar {
    min-height: 64px;
    display: flex;
    align-items: baseline;
    padding: 8px 24px 0;
    /* padding: 8px 15px 0;*/
    font-size: 20px;
    justify-content: space-between;
}

.tableSearchBar {
    padding: 8px 15px 0;
}

.action-buttons {
    display: inline-flex;
}

.example-header .mat-form-field,
.tableSearchBar .mat-form-field {
    padding-left: 15px;
}

.example-header .button,
.tableSearchBar .button {
    padding-left: 15px;
}

.example-header .action-buttons,
.tableSearchBar .action-buttons {
    display: inline-flex;
}

.mat-form-field {
    font-size: 13px;
    flex-grow: 1;
    margin-left: 15px;
}

.mat-table {
    overflow: auto;
    /* max-height: 450px; */
    max-height: 100%;
}

.mat-header-cell {
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #888888 !important;
}

.mat-icon-button i {
    font-size: 18px;
}

.tripsTable th,
.tripsTable td {
    text-align: center;
}

.PrintTHC {
    position: absolute !important;
    top: 10px;
    right: 10px;
    font-size: 26px !important;
    line-height: 20px !important;
}

.consignmentPrint {
    padding: 0;
}

.consignmentPrint label,
.consignmentPrint p {
    margin-bottom: 0px;
}

.consignmentPrint table,
.consignmentPrint table tr td {
    border-color: #ddd;
}

.consignmentPrint table input[type=text] {
    border: none;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;
    margin: 10px 5px;
}

.printLogo {
    padding: 10px;
    max-width: 220px;
}

.printLogo img {
    max-width: 100%;
}

.consignmentPrint table input[type=checkbox] {
    margin-top: 5px;
}

.consignmentPrint table textarea {
    width: 100%;
}

.consignmentPrint h2 {
    font-size: 22px;
    font-weight: 800;
}

.consignmentPrint h3 {
    font-size: 19px;
    font-weight: 700;
}

.consignmentPrint h4 {
    font-size: 17px;
    font-weight: 700;
}

.consignmentPrint h4 {
    font-size: 16px;
    font-weight: 700;
    padding: 10px 15px;
}

.consignmentPrint .borderAll {
    border: 1px solid #ccc;
    padding: 6px 15px;
}

.docsRecived {
    padding: 4px 10px;
}

.paddLeft15 {
    padding-left: 15px;
}

.paddRight15 {
    padding-right: 15px;
}

.consignmentPrint table,
.consignmentPrint table tr td,
.consignmentPrint table tr th {
    border-color: #ddd;
}

.consignmentPrint table input[type=text] {
    border: none;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;
    margin: 10px 5px;
    max-width: 100%;
}

.pad-all-10 {
    padding: 10px;
}

.pad-top-10 {
    padding-top: 10px;
}

.pad-right-10 {
    padding-right: 10px;
}

.pad-bottom-10 {
    padding-bottom: 10px;
}

.pad-left-10 {
    padding-left: 10px;
}

.mar-botm {
    margin-bottom: 0;
}

.tscTable {
    overflow: auto;
}

.pDate {
    display: inline-block;
    min-width: 120px;
}

.detailsTbl th {
    max-width: 100px;
}

.detailsTbl input[type=text] {
    max-width: 100px;
}

.matChipGroup .mat-chip:not(.mat-basic-chip):focus {
    background-color: #3f51b5;
    color: #fff;
}

.mat-chip {
    font-size: 10px;
    margin: 7px 2px !important;
}

.mat-tab-label,
.mat-tab-link {
    font-size: 14px;
    line-height: 16px;
    font-weight: bold;
    font-family: 'Gotham-Book', 'Muli', sans-serif;
}

.successPagePopup .okIcon {
    color: #4ca33d;
    font-size: 60px;
}

.successPagePopup h2 {
    color: #379626;
    font-weight: 700;
    font-size: 29px;
    padding: 0px 0px 5px 0;
}

.successPagePopup p {
    font-size: 22px;
    margin-bottom: 0;
}

.successPagePopup button {
    margin-top: 20px;
    margin-bottom: 15px;
}

.mat-progress-spinner {
    display: none;
}

.fileTitle,
.chooseFile {
    display: inline-block;
    width: 190px;
    text-align: left;
}

.w-75.mat-form-field {
    float: left;
}

.modal-header {
    padding: 8px 15px !important;
}

.whiteFormShodow .row .text-center .mat-warn {
    margin-right: 10px;
}

#loading-bar .bar {
    background: #ed223d;
}

#loading-bar-spinner {
    width: 100%;
    height: 100vh;
    background: rgba(252, 253, 253, .8);
    top: 0px;
    left: 0px;
}

#loading-bar-spinner .spinner-icon {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-top-color: #ed223d;
    border-left-color: #ed223d;
}

.podAttach {
    position: relative;
}

.podAttach:hover {
    background: rgba(0, 0, 0, .04);
}

.podAttach label {
    font-size: 17px;
    line-height: 40px;
    height: 40px;
    padding: 0 19px;
    width: 100%;
    font-weight: 400;
    margin-bottom: 0;
}

.podAttach input {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    overflow: hidden;
    opacity: 0;
    cursor: pointer;
}


/* .mat-form-field {float: left;} */

.selectRole {
    position: relative;
    float: left;
    margin-right: 10px;
}

.selectRole input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.selectRole label {
    display: inline-flex;
    padding: 7px 12px;
    border-radius: 24px;
    cursor: pointer;
    background-color: #e0e0e0;
    color: rgba(0, 0, 0, .87);
    font-size: 13px;
    line-height: 18px;
    outline: 0;
}

.selectRole input[type=radio]:checked+label {
    background-color: #3f51b5;
    color: #fff;
    box-shadow: 0 3px 3px -2px rgba(0, 0, 0, .2), 0 3px 4px 0 rgba(0, 0, 0, .14), 0 1px 8px 0 rgba(0, 0, 0, .12);
}

.modal-dialog {
    max-width: 80%;
}

.mat-badge-content {
    color: #fff;
    background: #e34e38!important;
}

.rightUserSec .mat-badge-medium .mat-badge-content {
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 11px;
    border: #fff 1px solid;
}


/* Responsive css start */

@media screen and (max-width: 767px) {
    .ng-star-inserted>.container>.p-l-20.p-r-20 {
        padding: 0!important;
    }
    .orderListing .row {
        margin: 0!important;
    }
    .orderListing .row .mobScroll {
        padding: 0!important
    }
    .mob-p-default .box2 {
        padding: 8px;
    }
    .mob-p-default .box2 .col-md-8 {
        padding-right: 0px;
    }
    .mob-p-0 .row.col-md-4.text-right {
        margin: 0px;
    }
    .orderListing.p-0 {
        padding: 0px 10px!important;
    }
    .leftToggleMenu {
        /* left: 5px; */
    left: 0px;
    top: 10px;
    font-size: 20px;
    }
    .logo {
        /* width: 150px;
        margin-left: 33px;
        margin-top: 3px;
        line-height: 13px; */
        width: 132px;
        margin-left: 6px;
        margin-top: 0;
        line-height: 0px;
    }
    .wrapper .mainContainer {
        padding: 80px 0px 10px 0px;
    }
    .whiteFormShodow h1 {
        font-size: 18px;
    }
    .header-strip h4 {
        margin-top: 0px;
        margin-bottom: 8px;
        font-size: 20px;
        font-weight: 700;
        color: #333;
        text-align: center;
    }
    .header-strip {
        margin-top: 5px;
    }
    .header-strip .text-right {
        text-align: center !important;
    }
    .mat-drawer-content {
        width: 100%;
    }
    .example-container {
        min-height: inherit;
    }
    .mat-elevation-z8 .col-sm-3.mTop15,
    .mat-elevation-z8 .col-sm-3.mTop10 {
        margin-top: 0px;
        padding-bottom: 20px;
    }
}

.podAttach {
    position: relative;
}

.podAttach:hover {
    background: rgba(0, 0, 0, .04);
}

.podAttach label {
    font-size: 17px;
    line-height: 40px;
    height: 40px;
    padding: 0 19px;
    width: 100%;
    font-weight: 400;
    margin-bottom: 0;
}

.podAttach input {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    overflow: hidden;
    opacity: 0;
    cursor: pointer;
}

.header-strip .back-btn {
    width: 24px;
    height: 24px;
    display: inline-block;
    color: rgb(255, 255, 255);
    line-height: 22px;
    text-align: center;
    margin-right: 8px;
    padding-right: 2px;
    background: #3f51b5;
    border-radius: 50%;
}

input[type="radio"]:disabled+label {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: .5;
}

.mapError {
    margin-top: 0px;
    font-size:90%;
    clear: both;
    display: block;
}
.mapError.top_margin{margin-top: -15px;}
.userGroupsDropDown {
    padding-left: 40px;
    margin-top: 8px;
}

.m-t-8 {
    margin-top: 8px;
    display: inline-block;
}

.redheadTxt {
    color: #a90f2d;
    font-size: 20px;
    font-weight: 400;
}

.bidsTable {
    padding: 10px 20px 20px 20px;
    background: #fff;
    display: inline-block;
    width: 100%;
}

.bidsTable ul {
    margin: 0;
    padding: 0;
}

.bidsTable ul li {
    float: left;
    width: 25%;
    padding: 4px 10px;
}

.bidsTable ul li .redheadTxt {
    padding-top: 15px;
    font-weight: 400;
    font-size: 20px;
    padding-right: 30px;
}

.bidsTable ul li hr {
    width: 100%;
    display: inline-block;
    margin: 10px 0;
}

.bidsTable strong {
    float: left;
}

.bidsTable p {
    margin-bottom: 0;
    float: left;
    padding-left: 10px;
}

.groupUsersIcon img {
    max-width: 24px;
}

.footerPagBtnDiv {
    padding: 5px 20px;
    background: #fff;
    display: inline-block;
    width: 100%;
}

.btmMainWhiteDiv {
    background: #fff;
    padding: 10px;
}

.groupDetails .redheadTxt {
    padding-top: 0 !important;
    margin-bottom: 0;
    padding-right: 0 !important;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    padding-bottom: 10px;
}

.groupDetails strong,
.groupDetails p {
    float: none;
    width: 100%;
    display: inline-block;
    padding-left: 0;
    padding-top: 5px;
}


/* .groupUsersTitle{padding: 15px 0 0 30px; margin-top:20px; margin-bottom: 10px;} */

.groupUsersTitle {
    padding: 0px 0 0 24px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.selectGroupBox {
    margin-top: 20px;
    margin-bottom: 1px;
    display: inline-block;
    width: 100%;
    background: #fff;
    padding: 10px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.selectGroupBox .groupBidLable {
    float: left;
    padding: 14px 10px 0px 0;
    color: #a91b48;
}

.selectGroupBox .w-75 {
    max-width: 300px;
}

.selectGroupBox .selectGroupBoxInner {
    width: 450px;
    margin: 0 auto;
}


/* .formWhiteBox .mat-tab-body-content { */

.mat-tab-body-content {
    overflow: inherit;
}

.mat-tab-body-content {
    height: 100%;
    overflow: inherit !important
}

.mat-row:hover {
    background-color: rgba(0, 204, 255, 0.137);
}

span.mat-placeholder-required.mat-form-field-required-marker {
    /* font-weight: bolder; */
    color: tomato;
}


/* label.mat-form-field-label.mat-input-placeholder.mat-form-field-placeholder {
    font-weight: bolder;
    color:yellow;
} */


/* span.mat-placeholder-required.mat-form-field-required-marker ::before {
    font-weight: 900;
    color:green;
} */


/* :required {
    background: red;
} */

input.mat-input-element:required {
    font-weight: bolder;
    color: #444;
}

input.mat-input-element:invalid {
    /* background: plum; */
    background: ivory;
    font-weight: bolder;
}


/* mat-select.mat-select-required:invalid{
    font-weight: bolder;
    background: ivory;
  }
  mat-select.mat-select-required.mat-select-placeholder{
    font-weight: bolder;
    background: ivory;
  }
  mat-select.mat-select-required:invalid + span{
    font-weight: bolder;
    color: #444;
  } */

mat-select.ng-invalid.ng-untouched {
    font-weight: bolder;
    background: ivory;
    color: #444;
}


/* mat-select.ng-invalid.ng-untouched .mat-select-placeholder{
    font-weight: bolder;
    color: #444;
  }
  mat-select.ng-valid:required +label{
    font-weight: bolder;
    color: #444;
  }
  input.mat-input-element.ng-invalid.ng-untouched{
    background: ivory;
    font-weight: bolder;
  }
   */


/* input:required:invalid + span {
    color: tomato;
    font-weight: bolder;
  } */

.mat-form-field-invalid .mat-form-field-label {
    /* color: #f44336; */
    font-weight: bolder;
}

.mat-form-field-required .mat-form-field-empty.mat-form-field-label {
    font-weight: bolder;
    background: ivory;
}

mat-select.mat-select-required span.mat-select-placeholder {
    font-weight: bolder;
}

.section-seperator {
    margin-bottom: 15px;
    margin-top: 15px;
    border-bottom-color: #03DAC6;
    border-bottom-width: medium;
    border-bottom-style: solid;
    width: 100%;
    text-align: left;
    font-weight: bolder;
    font-size: larger;
}

.mat-raised-button {
    min-width: 50px;
}


/* span + span {
  margin-left: 10px;
} */

@media screen and (max-width: 800px) {
     ::ng-deep .cdk-overlay-pane {
        right: unset !important;
    }
}

@media screen and (max-width: 800px) {
     ::ng-deep .cdk-overlay-pane {
        left: unset !important;
    }
}


/*JINGLE CSS*/

button:focus {
    outline: 0px dotted;
    outline: 0px auto -webkit-focus-ring-color;
}

.tabIcon {
    width: auto;
    height: 20px;
    margin-right: 5px;
}

.m-t-20 {
    margin-top: 20px;
}

.m-b-20 {
    margin-bottom: 20px;
}

.m-t-30 {
    margin-top: 30px;
}

.m-b-30 {
    margin-bottom: 30px;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
    height: 0px !important;
    border-bottom: 2px dotted #12aeeb !important;
    background: #fff !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: transparent !important;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: transparent !important;
}

textarea.mat-input-element {
    margin: 4px 0 !important;
}

.jLabel {
    /* padding: 0px !important; */
    font-weight: 600 !important;
    color: #646464 !important;
    font-size: 13px !important;
    letter-spacing: 0.72px !important;
}

.jBoldLabel {
    /* padding: 0px !important; */
    font-weight: 600 !important;
    color: #646464 !important;
    font-size: 12px !important;
    letter-spacing: 0.3px !important;
    margin-bottom: 0px !important;
}

.borderSolid2 {
    border: 1px solid #c2c2c2;
    padding: 10px 10px;
    border-radius: 5px;
}

.leftMenu .mat-expansion-indicator::after {
    color: rgb(255, 255, 255) !important;
    /* transform: rotate(-45deg) !important; */
    padding: 4px !important;
}

.mat-tab-header,
.mat-tab-nav-bar {
    border-bottom: 0px solid #f1f1f1 !important;
}

.mat-tab-label {
    height: 36px !important;
    padding: 0 10px !important;
    min-width: auto !important;
    border-bottom: 2px solid #f1f1f1 !important;
    letter-spacing: 0.72px;
}

.mat-tab-label-active {
    color: #12afec !important;
    background: rgba(216, 216, 216, 0.15) !important;
    /* opacity: 1 !important; */
    border-radius: 5px 5px 0px 0px !important;
    border-bottom: 2px solid !important;
}

.businessTypeTab .mat-tab-label-active {
    color: #12afec !important;
    background: #f3f3f3 !important;
    opacity: 1 !important;
    border-radius: 5px 5px 0px 0px !important;
    border-bottom: 2px solid !important;
}

.border-l-r {
    border-left: 1px solid #c6c6c6 !important;
    border-right: 1px solid #cacaca !important;
}

.mat-tab-label-active .mat-icon {
    color: #12afec !important;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #12afec !important;
    display: none !important;
}

.mat-button-toggle {
    border-bottom: 3px solid #ffffff;
    background: #fff;
    color: #c2c2c2;
}

.mat-button-toggle-checked {
    border-bottom: 3px solid #12afec;
    background: #fff;
    color: rgb(18, 175, 236) !important;
}

.mat-button-toggle-checked .mat-icon {
    color: rgb(18, 175, 236);
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 30px !important;
    padding: 2px 6px !important;
}

.mat-button-toggle-checked .mat-button-toggle-focus-overlay {
    border-bottom: none;
}

.mat-icon {
    color: #afafaf;
}

.mat-chip.mat-standard-chip {
    /* background-color: #f3f3f3 !important;
    color: #ababab !important; */
    min-height: 25px !important;
    /* padding: 5px 20px !important; */
}

.gray-chip {
    background-color: #f5f5f5 !important;
    color: #676767 !important;
}

.mat-checkbox-frame {
    border-color: rgb(18, 175, 236);
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #12afec;
}

.blueOutlineBtn {
    border: 2px solid #12afec !important;
    border-radius: 30px !important;
    box-shadow: none !important;
    color: #12afec !important;
}

.blueOutlineBtn .mat-icon {
    color: #12afec !important;
}

.acceptReject button {
    padding: 5px 3px;
    line-height: 24px;
    margin-right: 5px;
}

.acceptReject button span {
    padding: 4px 30px !important;
}

.acceptReject button.active {
    background: #12afec !important;
    color: #fff;
    border-radius: 30px;
    padding: 5px 3px;
    line-height: 24px;
}

.acceptReject button.active span {
    border: 2px dotted #f5ec48 !important;
    border-radius: 30px !important;
    padding: 4px 30px !important;
}

.btnBorder {
    border: 2px dotted #f5ec48 !important;
    border-radius: 30px !important;
    padding: 4px 30px !important;
}

.blueButton {
    background: #12afec !important;
    color: #fff !important;
    /* border: 3px dotted #03719c !important; */
    border-radius: 40px !important;
    padding: 7px 3px !important;
    line-height: 21px !important;
}

.saveButton span {
    border: 2px dotted #f5ec48 !important;
    border-radius: 30px !important;
    padding: 4px 30px !important;
}

.blueButton .mat-icon {
    color: #ffffff;
    margin-top: 3px;
    position: absolute;
    margin-left: -24px;
}

.nOrdBtn {
    padding: 4px 15px 4px 15px !important;
}

.nOrdBtn .ti-plus {
    background: #0099d3;
    border-radius: 50%;
    font-size: 10px;
    padding: 4px;
    line-height: 10px;
}

.addNewOrdBtn {
    background: #029ad4;
    border-radius: 50%;
    font-size: 11px;
    width: 21px !important;
    height: 21px !important;
    line-height: 23px;
    padding-left: 2px;
    position: absolute;
    margin-top: 1px;
    margin-left: -30px;
}

.newOrdBtn {
    font-family: 'Gotham-Book', 'Muli', sans-serif !important;
    font-size: 16px;
    letter-spacing: 0.54px;
    line-height: 22px !important;
}

.rowSaperator {
    border-bottom: 2px solid #dddddd;
    padding-bottom: 30px;
}

.menuIcon {
    height: 20px;
    margin-right: 10px;
}

.borderpic {
    border: #f5ec48 4px dotted;
    padding: 5px;
    border-radius: 100%;
}

.user-panel .user_img {
    width: 100%;
}

.user-panel .user_name {
    margin-bottom: 0px;
    font-weight: normal;
    color: #fff;
    /* font-size: 20px; */
    font-size: 18px;
    line-height: 20px;
    margin-top: 10px;
    letter-spacing: 1px;
}

.user-panel .user_company {
    margin-bottom: 20px;
    font-weight: 100;
    color: #f8ed43;
    font-size: 16px;
    letter-spacing: 0.8px;
    line-height: 18px;
    margin-top: 10px;
}

.topbarSaperator {
    margin: 0 10px 0 5px;
    border: 0.5px solid #eb953e;
    padding: 8px 0;
}

.redTitle {
    color: #a73223;
    font-size: 2em;
    font-weight: normal;
}

.topSearchBox .mat-icon {
    color: #a73223 !important;
}

.topSearchBox .mat-form-field-infix {
    color: #a73223 !important;
}

.topSearchBox .mat-form-field-underline {
    height: 6px !important;
    border-bottom: 0px dotted #f5ec48 !important;
    background-color: #a73223 !important;
}

.topSearchBox .mat-form-field-ripple {
    position: absolute;
    left: 0;
    width: 100%;
    transform-origin: 50%;
    transform: scaleX(1);
    opacity: 1;
    padding-top: 2px;
    border-bottom: 2px dotted #f5ec49;
    background: #a73223;
    transition: background-color .3s cubic-bezier(.55, 0, .55, .2);
}

.addButton {
    background-color: #ffffff !important;
    border: 2px solid #12afec !important;
    margin-left: 10px !important;
}

.addButton .mat-button-wrapper {
    padding: 0px 0 !important;
}

.addButton .mat-icon {
    color: #12afec !important;
}

.dropDownMenu .mat-expansion-panel {
    background: transparent !important;
    color: rgb(255, 255, 255) !important;
    box-shadow: none !important;
}

.dropDownMenu .mat-expansion-panel-header {
    padding: 12px 30px !important;
    /* height: 45px !important; */
}

.dropDownMenu .mat-expansion-panel-body {
    padding: 0 !important;
}

.dropDownMenu .mat-expansion-panel-header-title {
    color: #ffffff !important;
}

.dropDownMenu .leftSubmenu a {
    padding-left: 50px !important;
    padding-right: 0px !important;
}

.dropDownMenu .leftSubmenu .mat-icon {
    height: 14px !important;
    width: 14px !important;
    margin-right: 5px !important;
    font-size: 12px !important;
    color: #ffffff !important;
}

.dboardIcon {
    width: 35px;
    margin-top: 10px;
}

.ordDetailsIcon {
    width: 40px;
    position: absolute;
}

.box1 {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
    margin: 0.5rem 5px 0.5rem 5px;
    border-radius: 8px !important;
    /* padding: 15px 10px; */
    background: #fff;
}

.box2 {
    margin: 0.5rem 0 2rem 0;
    border-radius: 8px !important;
    padding: 20px 20px 5px 20px;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    background: #fff;
}

.placementSuccessRate-h6 {
    color: #12afec !important;
    margin-bottom: -30px;
    z-index: 999;
    position: relative;
    padding-left: 20px;
    padding-top: 16px;
    width: 50%;
}

.headerTitle {
    font-size: 22px !important;
    line-height: 26px !important;
    font-weight: 500 !important;
    color: rgba(100, 100, 100, 0.7);
    letter-spacing: 0.72px;
    text-transform: none;
}

.shipmentBox {
    font-size: 18px !important;
    line-height: 16px;
    font-weight: 300;
    color: rgba(100, 100, 100, 0.7);
    letter-spacing: 0.72px;
    text-transform: uppercase;
}

.blueTitle {
    font-size: 14px !important;
    line-height: 16px !important;
    font-weight: 600 !important;
    color: rgb(18, 175, 236);
    letter-spacing: 0.72px;
}

.degreeIcon::after {
    content: "\00b0"
}

.roundBlueIcon {
    font-size: 54px;
    border: 2px solid #12afec;
    height: 75px !important;
    width: 75px !important;
    line-height: 70px;
    color: #12afec;
    border-radius: 50%;
    text-align: center !important;
}

.roundGrayIcon {
    font-size: 54px;
    border: 2px solid #ababab;
    height: 75px !important;
    width: 75px !important;
    line-height: 70px;
    color: #ababab;
    border-radius: 50%;
    text-align: center !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
    color: #12afec !important;
    transition: opacity 2s ease-in !important;
    -moz-transition: opacity 2s ease-in !important;
    -webkit-transition: opacity 2s ease-in !important;
    display: none !important;
}

.checkbox-mt-10 label {
    margin-top: 10px !important;
}

.mat-mini-fab:not([class*=mat-elevation-z]) {
    box-shadow: none;
}

.gdatepicker .mat-form-field-flex {
    border: 1px solid #ddd;
    border-radius: 5px;
    /* height: 30px;
    align-items: center; */
}

.gdatepicker .mat-form-field-underline {
    display: none !important;
}

.gdatepicker .mat-form-field-prefix {
    border-right: 1px solid #e0e0e0 !important;
    margin-right: 10px !important;
}

.mat-mini-fab {
    width: 30px !important;
    height: 30px !important;
    line-height: 24px !important;
}

.gdatepicker .mat-icon-button {
    color: #12afec !important;
    font-size: 24px !important;
}

.mat-form-field-infix {
    padding: .5em 0;
    border-top: 0.3375em solid transparent;
}

.formSearchBox {
    width: 265px;
}

.formSearchBox .mat-form-field-infix {
    line-height: 0.125 !important;
    padding: 0.4375em 0 1em 0 !important;
}

.formSearchBox .mat-form-field-suffix .mat-icon {
    color: #12afec !important;
}


/* .historyFavorite .mat-fab .mat-button-wrapper {
    padding: 0px;
}
.historyFavorite .mat-fab {
    background: #12afec !important;
    margin: 0 5px !important;
    position: relative !important;
    z-index: 999 !important;
    width: 30px !important;
    height: 30px !important;
    box-shadow: none !important;
    overflow: hidden !important;
}
.historyFavorite .mat-icon {
    color: #ffffff !important;
    margin-left: 25%;
    font-size: 30px !important;
    line-height: 10px !important;
    width: 30px !important;
}



.historyFavorite button .mat-button-wrapper {
    background: #12afec !important;
    display: grid;
    font-size: 12px;
}
.historyFavorite button {padding: 0px;} */

.historyFavorite .mat-mini-fab .mat-button-wrapper {
    padding: 0px 0;
    display: inline-block;
    line-height: 24px;
}

.historyFavorite .mat-mini-fab {
    background-color: #12afec;
}

.historyFavorite .mat-icon {
    color: #fff;
}

.historyFavorite .fabBlueTitle {
    color: #12afec !important;
    font-size: 9px !important;
}

@media only screen and (max-width: 600px) {
    .fabBlueTitle span {
        display: none;
    }
    .col-md-4 {
        margin-bottom: 5px;
    }
    .roundBlueIcon {
        font-size: 36px;
        border: 2px solid #12afec;
        height: 55px !important;
        width: 55px !important;
        line-height: 50px;
        color: #12afec;
        border-radius: 50%;
        text-align: center !important;
    }
    .roundGrayIcon {
        font-size: 36px;
        border: 2px solid #ababab;
        height: 55px !important;
        width: 55px !important;
        line-height: 50px;
        color: #ababab;
        border-radius: 50%;
        text-align: center !important;
    }
    .historyFavorite {
        overflow: hidden;
    }
}

.m-t-m-40 {
    margin-top: -40px !important;
}

.m-b-m-45 {
    margin-bottom: -45px !important;
}

.m-t-m-5 {
    margin-top: -5px !important;
}

.m-l-m-5 {
    margin-left: -5px !important;
}

.timeToggle button {
    text-align: left !important;
}

.f-left {
    float: left !important;
}

.f-right {
    float: right !important;
}

.breakLabelToggle .mat-button-toggle-label-content {
    display: flex;
    line-height: 12px !important;
    white-space: normal !important;
    /* width: 117px !important; */
    text-align: left !important;
    padding-top: 6px !important;
    font-size: 13px !important;
    align-items: center;
}

.width-100 {
    width: 100% !important;
}

.width25 {
    width: 25px !important;
}

.mat-cell,
.mat-footer-cell {
    color: #888888;
}


/* @media (min-width: 1200px){
    .container {
        padding: 0px 70px;
    }
} */

.orderListing td.mat-cell {
    /* padding-top: 2em;
    padding-bottom: 2em;
    border-bottom-width: 0px; */
    padding: 0em;
    /* padding: 0.5em; */
    padding-top: 1.7em;
    padding-bottom: 1.7em;

    /* border-bottom-width: 10px; */
    border-color: #f9f9f9;
    font-weight: 600;
}
.orderListing tr.mat-row {

}
.orderListing .mat-row {
    /* -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1); */
    /* border-radius: 8px; */
    min-height: 92px;
    /* letter-spacing: 0.88px; */
    /* color: rgba(100, 100, 100, 0.7); */
    /* background: #fff; */
    margin-bottom: 20px;
    /* margin-bottom: 10px; */
    border-radius: 8px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  /* border: none; */
}

.orderListing .mat-row:hover {
    background-color: rgba(255, 255, 255, 1);
    /* box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.17);
    box-shadow: 0px 2px 18px 0px rgba(0,0,0,0.5); */
}

.orderListing .mat-row:before {
    box-sizing: border-box;
    content: '';
    position: absolute;
    left: 0;
    right: 2px;
    display: block;
    /* height: 90px; */
    /* box-shadow: 0 1px 9px 1px rgba(0, 0, 0, 0.28); */
    /* -webkit-box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.28); */
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    border-radius: 0px;
}

.orderListing .mat-header-row:before {
    box-sizing: border-box;
    content: '';
    position: absolute;
    left: 0;
    right: 2px;
    display: block;
    -webkit-transition: all 300ms ease-out 0s;
    -moz-transition: all 300ms ease-out 0s;
    -ms-transition: all 300ms ease-out 0s;
    -o-transition: all 300ms ease-out 0s;
    transition: all 300ms ease-out 0s;
    /* box-shadow: 0 1px 9px 1px rgba(0, 0, 0, 0.28); */
    -webkit-box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.28);
    border-radius: 0px;
}

.orderListing .mat-row:hover:before {
    -webkit-transition: all 300ms ease-out 0s;
    -moz-transition: all 300ms ease-out 0s;
    -ms-transition: all 300ms ease-out 0s;
    -o-transition: all 300ms ease-out 0s;
    transition: all 300ms ease-out 0s;
    /* box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.50); */
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.17);
    border-radius: 0px;
}

.orderListing .mat-progress-bar-fill::after,
.orderDetails .mat-progress-bar-fill::after,
#orderTripDetails .mat-progress-bar-fill::after {
    background-color: #12afec !important;
}

.completedProgress .mat-progress-bar-fill::after {
    background-color: #95da48 !important;
}

.orderListing .mat-progress-bar-buffer,
.mat-progress-bar-buffer {
    background-color: #cecece !important;
}

.orderListing td.mat-cell:last-of-type {
    padding-right: 0px !important;
    border-left: 1px solid #cecece;
}

.orderListing th.mat-header-cell {
    border-bottom-width: 0px;
}

.orderListing .mat-tab-label {
    padding: 0 30px 0 20px !important;
    font-weight: 500 !important;
    font-family: 'Gotham-Book', 'Muli', sans-serif !important;
    color: #777;
    /* color: #a7a7a7; */
    letter-spacing: 0.72px;
    opacity: 1;
}

.orderListing .mat-tab-body,
.orderListing .mat-table {
    background: transparent;
    padding-left: 5px;
    padding-right: 5px;
}

.orderListing .mat-tab-label {
    border-bottom: 2px solid transparent !important;
}

.orderListing .mat-tab-label-active {
    border-bottom: 2px solid #12afec !important;
}

.orderListing .mat-tab-header {
    border-bottom: 2px solid #e1e1e1 !important;
}

.mat-PendingApproval-bg {
    background-color: #e8715f !important;
    color: #ffffff !important;
}

.mat-InTransit-bg {
    background-color: #6dcdf3 !important;
    color: #ffffff !important;
}

.mat-Upcoming-bg {
    background-color: #fbbe45 !important;
    color: #ffffff !important;
}

.mat-PendingQuote-bg {
    background-color: #d6d6d6 !important;
    color: #7b7b7b !important;
}

.mat-Accept-bg,
.mat-OnTime-bg {
    background-color: #95da48 !important;
    border: 1px solid transparent;
    color: #ffffff !important;
}

.mat-Reject-bg {
    background-color: #ffffff !important;
    border: 1px solid #838383 !important;
    color: #838383 !important;
}

.vertBlueIcon {
    color: #12afec;
}

.orderStatusToggle {
    border: solid 0px rgba(0, 0, 0, .12) !important;
}

.orderStatusToggle .mat-button-toggle {
    border-left: solid 0px rgba(0, 0, 0, .12) !important;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1) !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    margin-right: 15px !important;
    margin-left: 3px !important;
    border-radius: 5px !important;
}

.orderStatusCount {
    font-size: 18px;
    font-weight: 300;
    line-height: 22px;
    padding: 0 5px 5px 5px;
    color: #777;
}

.orderStatusCount span {
    font-size: 31px;
    font-weight: 400;
    margin: 0 15px 0 15px;
    position: relative;
    bottom: -7px;
}

.mat-button-toggle:hover {
    border-bottom: 3px solid #12afec !important;
    color: #12afec !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: #12afec;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: #12afec;
}

.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    display: none !important;
}

.orderDetails .mat-expansion-panel-header .mat-content,
#orderTripDetails .mat-expansion-panel-header .mat-content {
    display: inline-flex !important;
}

.orderDetails .mat-expansion-indicator::after,
#orderTripDetails .mat-expansion-indicator::after {
    margin-top: -5px !important;
    color: #12afec !important;
}

.orderDetails .mat-expansion-indicator,
#orderTripDetails .mat-expansion-indicator {
    padding: 8px 13px;
    border-radius: 50%;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.13);
}

.shareDetailBtn,
.shareDetailBtn .mat-icon {
    background: #12afec !important;
    color: #fff !important;
}

.excelDownload {
    position: absolute !important;
    right: 0 !important;
    margin-top: 25px !important;
    background: #12afec !important;
    color: #fff !important;
    padding: 0px 25px !important;
}

.excelDownload .fa-download {
    margin: 0 7px;
}

.tripDetailsBtnLink {
    position: absolute !important;
    right: -25px !important;
    top: 150px !important;
    z-index: 999 !important;
}

.tripDetailsBtnLink .mat-icon {
    color: #12afec !important;
}

@media screen and (max-width: 767px) {
    .mat-form-field {
        width: 100%;
    }
    .rowSaperator {
        border-bottom: 0px solid #dddddd;
    }
    .mobBorderNone {
        border: 0px;
    }
    iframe {
        border: 2px solid #12afec;
        border-radius: 5px;
    }
    .mob-m-default {
        margin-right: 15px !important;
        margin-left: 15px !important;
    }
    .mob-p-default {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }
    .mob-p-0 {
        padding: 0px !important;
    }
    .mob-m-0 {
        margin: 0px !important;
    }
    /* .mat-button-toggle-group {display: grid !important;} */
    .text-right {
        right: 5px !important;
    }
    .header {
        /* padding: 5px 20px 135px 20px; */
        padding: 5px 20px 50px 20px;
        background-size: auto 100%;
    }
    .formSearchBox {
        margin: 0px !important;
        padding: 0px !important;
        width: 100% !important;
    }
    .historyFavorite {
        position: absolute !important;
        right: 0 !important;
        width: 150px !important;
    }
    .orderStatusToggle {
        overflow: auto !important;
        width: 100% !important;
    }
    .rightUserSec {
        position: absolute !important;
        right: 0 !important;
        top: -9px !important;
        margin: 5px;
    }
    .notificationSection {
        float: left;
        margin-right: 0;
        position: relative;
        margin-top: 2px;
    }
    .headerSearchBar {
        border-left: none !important;
        margin: 5px 20px 10px 0;
        padding: 0px 40px 0px;
    }
    .advancesearchbox {
        margin: 10px 0px !important;
    }
    .form-searchbar {
        width: 150px!important;
    }
    .topbarSaperator {
        display: none !important;
    }
    .mobScroll {
        overflow: auto !important;
        width: 100% !important;
        min-height: 40px !important;
        /* height: 40px; */
    }
    .orderListing td.mat-cell {
        min-width: 100px !important;
    }
    .mat-form-field-wrapper {
        /* width: 320px !important; */
        width: 100% !important;
    }
    td.mat-cell:first-of-type,
    td.mat-footer-cell:first-of-type,
    th.mat-header-cell:first-of-type {
        padding-left: 10px !important;
        min-width: 50px !important;
    }
    .orderListing td.mat-cell:last-of-type {
        min-width: 50px !important;
    }
    .orderStatusCount span {
        font-size: 16px !important;
        margin: 0px !important;
        bottom: 0px !important;
    }
    .orderStatusToggle .mat-button-toggle {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        margin-right: 5px !important;
        margin-left: 5px !important;
        border-radius: 5px !important;
    }
    .redTitle {
        font-size: 18px !important;
    }
    .mat-chip-list-wrapper {
        min-width: 150px !important;
    }
    .mat-expansion-panel-body {
        padding: 0 0px 0px !important;
    }
    .detailsPageBtn {
        width: 48%;
        float: left;
    }
    .mob-select {
        width: 88% !important;
    }
    .tripDetailsBtnLink {
        right: 12px !important;
        top: -6px !important;
        border: 1px solid #12afec !important;
        border-radius: 0px !important;
    }
    .placementSuccessRate-h6 {
        margin-bottom: 0px;
        padding-top: 0px;
    }
    .orderListing td.mat-cell:last-of-type {
        border-left: 0px solid #cecece !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    body {
        font-size: 11px;
    }
    iframe {
        border: 2px solid #12afec;
        border-radius: 5px;
    }
    .mob-p-0 {
        padding: 0px !important;
    }
    .mob-m-0 {
        margin: 0px !important;
    }
    /* .wrapper .mainContainer {
        padding: 5.5em 0em 0em 0em;
    } */
    .mobScroll {
        overflow: auto !important;
        width: 100% !important;
    }
    .orderListing td.mat-cell {
        min-width: 100px !important;
    }
    td.mat-cell:first-of-type,
    td.mat-footer-cell:first-of-type,
    th.mat-header-cell:first-of-type {
        /* padding-left: 10px !important; */
        min-width: 50px !important;
    }
    .orderListing td.mat-cell:last-of-type {
        min-width: 50px !important;
    }
    .mat-chip-list-wrapper {
        min-width: 150px !important;
    }
    .recentlyAddedDropdown {
        position: absolute !important;
        right: 20px;
        font-size: 12px;
        width: 100px !important;
    }
    .orderListing td.mat-cell:last-of-type {
        border-left: 0px solid #cecece !important;
    }
}


/* {
    position: fixed;
    bottom: 20px;
    width: 284px;
    background: #e34e38;
} */

.paymentsMiddleTabs .mat-tab-header {
    position: absolute;
    z-index: 999;
    right: 15px;
    top: 5px;
}

.paymentsMiddleTabs .mat-tab-label {
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0.48px !important;
    font-weight: normal;
    font-family: 'Gotham-Book', 'Muli', sans-serif;
    height: 36px !important;
}

.paymentsMiddleTabs .mat-tab-body {
    padding: 0px;
    background: transparent;
}

.quoteDetailsTable {
    width: 100%;
}

.quoteDetailsTable .mat-row {
    background-color: transparent !important;
    border-left: 5px solid transparent;
}

.quoteDetailsTable .mat-row:hover {
    background-color: rgba(0, 204, 255, 0.137) !important;
    border-left: 5px solid #12afec;
}

.quoteDetailsTable .mat-table tbody {
    border-top: 1px solid rgba(0, 0, 0, .12) !important;
}

.mat-button-toggle-checked .mat-button-toggle-focus-overlay {
    border-bottom: solid 42px;
}

.bottomImg {
    position: relative;
    bottom: 0px;
    padding-bottom: 20px;
    width: 250px;
    padding-top: 12px;
    background: #e34e38;
}

.mat-expanded .mat-expansion-panel-header-title {
    color: #f5ec48 !important;
}

.mat-expanded .mat-expansion-indicator::after {
    color: rgb(245, 236, 72) !important;
}

.left-sidenav .mat-expansion-panel {
    background: transparent;
    color: rgb(250, 250, 250);
    overflow: visible;
}

.left-sidenav .mat-expansion-panel-header-title {
    color: rgb(250, 250, 250) !important;
}

.left-sidenav .mat-expansion-panel-spacing {
    margin: 0px 0;
}

.left-sidenav .mat-expansion-panel {
    border-radius: 0px;
}

.left-sidenav .mat-accordion .mat-expansion-panel:first-of-type {
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
}

.left-sidenav .mat-accordion .mat-expansion-panel:last-of-type {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

.left-sidenav .mat-expansion-panel:not([class*=mat-elevation-z]) {
    box-shadow: none;
}

.singleMenuLink .mat-expansion-panel-content,
.singleMenuLink .mat-expansion-indicator {
    display: none !important;
}

.left-sidenav .singleMenuItem {
    height: 48px;
    padding: 0 24px;
    font-size: 15px;
    font-weight: 400;
    background: transparent;
    color: rgb(250, 250, 250);
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    font-family: "Gotham-Medium", Roboto, "Helvetica Neue", sans-serif !important;
}

.submenu li a {
    height: 36px;
    padding: 0 0px;
    font-size: 15px;
    font-weight: 400;
    background: transparent;
    color: rgb(250, 250, 250);
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
}

.submenu li a:hover,
.submenu li a.active,
.submenu li a.active .mat-icon,
.submenu li a:hover .mat-icon {
    color: #f5ec48;
}
.left-sidenav .mat-expansion-panel:hover .mat-icon{
    color: unset !important;
}
#icon2{
    display: none;
}
.submenu li:hover #icon1, .submenu li a.active #icon1{
    display: none;
}
.submenu li:hover #icon2, .submenu li a.active #icon2{
    display: inline-block;
}
.submenu .mat-icon {
    color: #ffffff;
}

.submenu .material-icons {
    font-size: 12px;
    line-height: 26px;
}

.left-sidenav .singleMenuItem.active,
.left-sidenav .singleMenuLink.active,
.left-sidenav .mat-expansion-panel.active .mat-expansion-panel-header,
.left-sidenav .mat-expanded .mat-expansion-panel-header
/* .left-sidenav .mat-expansion-panel-header:hover  */
{
    background: #a73223;
    color: #f5ec48 !important;
    background-image: url(assets/img/active-menu.png) !important;
    background-size: contain !important;
}

.left-sidenav .mat-expansion-panel-header:hover .mat-expansion-indicator::after,
.left-sidenav .mat-expansion-panel.active .mat-expansion-indicator::after {
    color: #f5ec48 !important;
}

.left-sidenav .mat-expanded .mat-expansion-panel-header-title,
.left-sidenav .mat-expansion-panel.active .mat-expansion-panel-header-title,
.left-sidenav .mat-expansion-panel-header:hover .mat-expansion-panel-header-title {
    color: #f5ec48 !important;
}

.left-sidenav .singleMenuItem:hover .mat-expansion-panel-header-title,
.left-sidenav .singleMenuLink.active .mat-expansion-panel-header-title {
    color: #f5ec48 !important;
}

.submenu li a.active .left-sidenav .mat-panel-title {
    color: #f5ec48 !important;
}

.leftMenu .mat-expansion-panel-header.mat-expanded,
.dropDownMenu .mat-expansion-panel-header:hover {
    background: transparent !important;
    color: #f5ec48;
    background-image: none !important;
    background-size: contain !important;
}
.submenu li a.active {
    background-image: url(assets/img/active-menu.png) !important;
    background-size: contain !important;
    color: #f5ec48 !important;
    background: #a73223;
}

.dashboardMenu,
.ordersMenu,
.tripsMenu,
.paymentsMenu,
.reportsMenu {
    width: 30px;
    height: 30px;
    margin-right: 4px;
    background-position: 0px 2px;
    background-size: 24px;
    background-repeat: no-repeat;
}

.dashboardMenu {
    background-image: url(assets/img/icons/dashboardw.png);
}

.dMenu.active .dashboardMenu,
.dMenu:hover .dashboardMenu {
    background-image: url(assets/img/icons/dashboardy.png);
}

.ordersMenu {
    background-image: url(assets/img/icons/ordersw.png);
}

.left-sidenav .mat-expanded .ordersMenu,
.oMenu.active .ordersMenu,
.oMenu:hover .ordersMenu {
    background-image: url(assets/img/icons/ordersy.png);
}

.tripsMenu {
    background-image: url(assets/img/icons/tripsw.png);
}

.left-sidenav .mat-expanded .tripsMenu,
.tMenu.active .tripsMenu,
.tMenu:hover .tripsMenu {
    background-image: url(assets/img/icons/tripsy.png);
}

.paymentsMenu {
    background-image: url(assets/img/icons/paymentw.png);
}

.left-sidenav .mat-expanded .paymentsMenu,
.pMenu.active .paymentsMenu,
.pMenu:hover .paymentsMenu {
    background-image: url(assets/img/icons/paymenty.png);
}

.reportsMenu {
    background-image: url(assets/img/icons/reportsw.png);
}

.left-sidenav .mat-expanded .reportsMenu,
.rMenu.active .reportsMenu,
.rMenu:hover .reportsMenu {
    background-image: url(assets/img/icons/reportsy.png);
}

.truckMenu {
    background-image: url(assets/img/icons/tripsw.png);
}

.left-sidenav .mat-expanded .truckMenu,
.rMenu.active .truckMenu,
.rMenu:hover .truckMenu {
    background-image: url(assets/img/icons/tripsy.png);
}

.mat-select-arrow {
    width: 0 !important;
    height: 0 !important;
    border-left: 0px solid transparent !important;
    border-right: 0px solid transparent !important;
    border-top: 0px solid !important;
    margin: 0 4px !important;
}

.selectBox .mat-form-field-underline {
    background-color: transparent !important;
    border: 0px dashed #12afec !important;
}

.selectBox .mat-form-field-wrapper {
    border: 1px solid #12afec !important;
    border-radius: 5px !important;
    padding: 5px !important;
}

.selectdiv {
    position: relative;
    float: left;
}

select::-ms-expand {
    display: none;
}

.selectdiv:after {
    content: '<>';
    font: 17px "Consolas", monospace;
    color: #12afec;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    right: 0px;
    position: absolute;
    pointer-events: none;
}

.dryBusinessType {
    background-image: url(assets/img/icons/dryg.png) !important;
    background-size: 26px 26px !important;
    background-repeat: no-repeat !important;
    background-position: 3px center !important;
}

.dryBusinessType:hover {
    background-image: url(assets/img/icons/dry.png) !important;
}

.borderBottom2 {
    border-bottom: 2px solid #e1e1e1;
}

.mat-tab-body {
    background: #fff;
    padding: 12px;
    border-radius: 10px;
}

.mat-button-toggle-checked .mat-button-toggle-focus-overlay {
    border-bottom: solid 36px transparent !important;
}

.orderDetails .mat-expansion-panel {
    background: transparent !important;
    color: rgba(0, 0, 0, .87);
}

.orderDetails .mat-expansion-panel-body {
    padding: 0 15px 15px;
}

.bgNone {
    background-color: transparent !important;
}

.mat-tab-label-active g,
.mat-tab-label-active svg,
.mat-tab-label-active svg path,
.mat-button-toggle-checked g,
.mat-button-toggle-checked svg,
.mat-button-toggle-checked svg path,
.mat-button-toggle:hover g,
.mat-button-toggle:hover svg,
.mat-button-toggle:hover svg path {
    stroke: #12afec;
    fill: #12afec;
    ;
}

.mat-button-toggle svg {
    margin-right: 5px;
    width: 45px;
}

.newOrderForm .mat-tab-label {
    border-right: 1.5px solid rgba(100, 100, 100, 0.07);
    border-radius: 0px !important;
}

.mat-tab-body .mat-tab-label {
    border-right: 0px solid rgba(100, 100, 100, 0.07);
    border-radius: 0px !important;
}


/************search section syed shujait modify****************/

.headerSearchBar {
    padding: 5px 15px;
}

.headerSearchBar i {
    font-size: 18px;
}

.headerSearchBar {
    float: left;
    margin: 5px 20px 10px 0;
    border-left: #a73223 1px solid;
    position: relative;
    padding: 0px 0px 5px 40px;
}

.form-searchbar {
    background: rgba(0, 0, 0, 0.0) url(assets/img/Glasswing-Dashboard-Updated.jpg) 0px 100% repeat-x;
    border: none;
    padding: 5px 10px 13px 26px;
    color: #a73223;
    width: 230px;
    max-width: 100%;
    height: auto;
}

.form-searchbar i {
    font-size: 20px;
}

.headerSearchBar .form-control {
    border-radius: 100px;
}

.headerSearchBar .search-btn {
    position: absolute;
    left: 43px;
    color: #a73223;
    font-weight: 600;
    top: 9px;
}

.advancesearchbox {
    border-radius: 40px;
    margin: 10px 15px;
    float: left;
    background: #a73223;
    padding: 2px;
}

.advancesearchbox .addSearch {
    border: dashed #f5ec48 1px;
    border-radius: 40px;
    font-size: 14px;
    color: #fff;
    text-decoration: none;
    display: block;
    padding: 5px 20px;
    cursor: pointer;
}


/* .leaflet-marker-icon{border-radius: 100%; background: #f5ec48;padding:2px;height:42px!important;width: 42px!important;box-shadow: rgba(0,0,0,0.6) 0px 0px 8px; } */

.leaflet-marker-icon img {
    height: 36px;
    width: auto;
}

.mat-drawer-content {
    overflow: visible!important;
}


/*********************syed shujait******************/

.orderStatusToggle .mat-button-toggle {
    width: 250px;
    height: 64px;
    text-align: left
}

.orderStatusToggle .mat-button-toggle .mat-button-toggle-label-content {
    display: block;
    padding: 10px!important;
}

.orderStatusToggle .mat-button-toggle .orderStatusCount {
    display: block;
    text-align: left
}

.orderStatusToggle .mat-button-toggle .orderStatusCount span {
    /* width: 29px; */
    display: inline-block
}

.width30 {
    width: 30px;
}

.font-size15 {
    font-size: 15px;
}
.font-size18 {
    font-size: 18px;
}

.mat-tab-labels {
    display: flex
}

.mat-tab-label .mat-tab-label-content {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    font-size: 18px;
    line-height: 22px;
}

tr.mat-header-row {
    height: 56px;
}

.mat-checkbox-inner-container-no-side-margin {
    margin-left: 0;
    margin-right: 0;
}

.mat-checkbox .mat-checkbox-ripple {
    position: absolute;
    left: calc(50% - 20px);
    top: calc(50% - 20px);
    height: 40px;
    width: 40px;
    z-index: 1;
    pointer-events: none;
}

.mat-checkbox-inner-container {
    display: inline-block;
    height: 14px !important;
    line-height: 0;
    margin: auto;
    margin-right: 8px;
    order: 0;
    position: relative;
    vertical-align: middle;
    white-space: nowrap;
    width: 14px !important;
    flex-shrink: 0;
}

.mat-checkbox-frame {
    background-color: transparent;
    transition: border-color 90ms cubic-bezier(0, 0, .2, .1);
    border-width: 2px;
    border-style: solid;
}

.mat-checkbox-background,
.mat-checkbox-frame {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    border-radius: 2px;
    box-sizing: border-box;
    pointer-events: none;
}
.mat-checkbox-layout {
    margin-bottom: 0px !important;
}

.orderListing .mat-row {
    /* border-radius: 8px; */
    /* height: 92px; */
    letter-spacing: 0.88px;
    color: rgba(100, 100, 100, 0.7);
    background: #fff;
}

td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type,
th.mat-header-cell:first-of-type {
    padding-left: 24px;
}

.orderListing td.mat-cell {
    font-size: 14px;
    font-weight: 400;
}

.div_claButton {
    border-left: 1px solid rgb(204 204 204 / 50%);
    height: 100%;
}

.div_claButton>div {
    height: 50%;
}

.btn_outer:first-child {
    border-bottom: #d1d1d1 1px solid;
    border-radius: 0px 8px 0px 0px;
}
.btn_outer:last-child {
    border-bottom: none;
    border-radius: 0px 0px 8px 0px;
}

.btn_outer {
    padding: 15px 5px 13px 39px;
    color: #12AFEC;
    background: url(assets/img/noun_View_772431.png) 10px center no-repeat;
    display: block;
    /* margin-right: 2px; */
    position: relative;
    z-index: 111;
    font-size: 14px;
    cursor: pointer
}

.btn_outer i {
    vertical-align: middle
}

.div_claButton .btn_outer:hover {
    color: #fff;
    background: url(assets/img/noun_View_772431h.png) 10px center no-repeat #12AFEC
}

.classbillSection {
    font-size: 16px;
}

.colorDate {
    color: #D5D5D5;
    font-size: 14px
}

.mat-expansion-panel-body {
    overflow: hidden;
}

.reViewSection .mat-progress-bar .mat-progress-bar-fill::after {
    background-color: #666!important;
}

/* .siteFont, .siteLoading, .mat-tab-group, .mat-form-field-empty.mat-form-field-label, .mat-checkbox-layout, .mat-table, .mat-button, .mat-fab, .mat-icon-button, .mat-mini-fab, .mat-raised-button, .mat-radio-button, .mat-expansion-panel-content, .mat-expansion-panel-header, .mat-stepper-horizontal, .mat-stepper-vertical, .mat-form-field {
  font-family: 'Gotham-Book', 'Muli', sans-serif !important;

  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
} */

.customer-dashboard .mat-tab-body {
  padding-right: 0px;
  padding-left: 0px;
}
.paymentsMiddleTabs .mat-tab-label .mat-tab-label-content {
  font-size: 12px;
}
.paymentList .mat-cell, .paymentList .mat-header-cell {
  min-width: 100px;
  max-width: 200px;
  margin-left: 10px;
}
/* .paymentList .mat-cell{
  min-width: 100px;
  margin-left: 10px;
} */
.paymentList .mat-row {
  /* margin-bottom: 20px; */
  border-radius: 8px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  margin: 10px auto;
}
.mat-column-id {
  max-width: 220px;
}
 .mat-column-consignment_nos {
  max-width: 150px;
  margin-left: 10px;
}
/*
.mat-column-invoice_total_amt {
  max-width: 200px;
  margin-left: 10px;
} */
.mat-column-submitted_dt {
  max-width: inherit;
}
.mat-column-edit{
  justify-content: flex-end;
}
.payment-note {
  /* width: 106.1px; */
  /* height: 22px; */
  /* border-radius: 20px;
  background-color: #fbbe45;
  padding-top:2px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px; */

    border-radius: 20px;
    background-color: #fbbe45;
    /* padding-top: 2px; */
    /* padding-bottom: 2px; */
    /* padding-left: 10px; */
    /* padding-right: 10px; */
    padding: 5px 7px;
    font-size: 10px;
    text-align: center;
}
.payment-note-red {
  /* width: 106.1px; */
  /* height: 22px; */
  border-radius: 20px;
  background-color: #e34e38;
  padding: 5px 5px;
  font-size: 9px;
  text-align: center;
  /* padding-top:2px;
  padding-bottom: 2px;
  padding-left: 10px;
  padding-right: 10px; */
}

.leaflet-popup-content {
    height: 110px!important;
    width: 220px!important;
}


.popButton{
    background: #7ED321;
    border-radius: 35px;
    color: #fff;
    border: none
  }



/* mat-table.table_list   mat-row.active mat-cell:nth-child(1)  {
    border-left: 1px solid !important;
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
}
mat-table.table_list   mat-row.active mat-cell:nth-child(2)  {
    border-left: 1px solid !important;
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
}mat-table.table_list   mat-row.active mat-cell:nth-child(3)  {
    border-left: 1px solid !important;
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
}mat-table.table_list   mat-row.active mat-cell:nth-child(4)  {
    border-left: 1px solid !important;
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
} */

.confirm_btn.saveButton span:nth-child(2) {
    border: 0px !important;
}
.confirm_btn.blueButton.saveButton span:nth-child(2) {
    border: 0px !important;
}
.orderDetails .mat-expansion-indicator::after,
#orderTripDetails .mat-expansion-indicator::after {
    margin-top: -5px !important;
    color: #12afec !important;
}

.order_details mat-expansion-panel-header span{
flex: unset;
padding: 0 10px;
}
.order_details mat-tab-body{
    padding: 0px;
}
.order_details .mat-tab-label {
    color: #646464 !important;
    font-weight: 500;
    opacity: 0.5;
}
.order_details .mat-tab-label-active{
    background: transparent !important;
    background: transparent !important;
    color: #646464 !IMPORTANT;
    font-weight: normal;
    border-bottom: 2px solid #12afec !important;
    opacity: 1;
}
.order_details .mat-tab-list{
margin-bottom: 20px;
}
.order_details .row.col-md-12.m-0.m-b-10{
    padding-left: 0px;
    padding-right: 0px;
}
.consignment_note .mat-form-field-infix{
    margin-bottom: 5px;
    position: unset;
}
.consignee_textarea .mat-form-field-infix{
margin-top:0px;
margin-bottom:0px;
padding-top: 0px;
padding-bottom: 0px;
}
.consignee_textarea textarea.mat-input-element{
margin-top:0px !important;
margin-bottom: 0px !important;
}
.search_Result .mat-header-cell{
    font-weight: 500 !important;
}
.search_Result .mat-header-cell .mat-checkbox .mat-checkbox-layout{
    margin-bottom: 0px;
}
.icon_bell.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
    right: -11px;
    margin-top: 4px;
}
.detail_orders .mat-chip-list-wrapper{
    width: fit-content;
    margin: auto;
}
.tab-style .mat-tab-label .mat-tab-label-content {
    font-size: 16px;
    font-weight: normal;
}
.tab-style .mat-tab-label-active {
    background: transparent !important;
}

@media(max-width:1199px){
    .width-150{
    width: 100px !important;
    }
    .mat-form-field-infix{
    width: 100% !important;
    }
    .dry .mat-form-field-infix {
    width: 180px !important;
    }
    .spot .mat-form-field-infix {
    width: 260px !important;
    }
    .exim .mat-form-field-infix {
    width: 280px !important;
    }
    .spot .ship{
    margin-left: 32px !important;
    }

}
@media(max-width:1199px){
    .width-150{
        width: 100% !important;
      }
}
.th-center .mat-sort-header-container{
    justify-content: start;
}

@media screen and (max-width: 767px){
    .dry .mat-chip-list .mat-chip-list-wrapper {
        min-width: auto !important;
    }
}

@media (max-width: 768px) {
    .form-searchbar {
      border: none;
      padding: 5px 10px 13px 22px;
      color: #a73223;
      width: 150px;
      max-width: 100%;
      height: auto;
  }
  .headerSearchBar {
    float: left;
    margin: 5px 10px 10px 0;
    border-left: #a73223 1px solid;
    position: relative;
    padding: 0px 0px 5px 40px;
  }

  .saveButton span {
    border: 2px dotted #f5ec48 !important;
    border-radius: 30px !important;
    padding: 4px 10px !important;
    font-size: 12px;
  }
  .reefer .refer-h {
    padding: 0px;
  }
  .reefer .mat-form-field-infix{
    margin-left: 0px;
  }

  .refer-spot .p-l-45 {
      padding: 0px !important;
  }
  .refer-spot .p-l-45 {
    padding-left: 15px !important;
  }
  .cargo-type {
      padding-left: 0px !important;
  }
  .cargo-weight {
    padding-left: 17px !important;
  }

  }

    .spot .ship {
    margin-left: 19px !important;
    }
    .spot .ship-down {
    margin-left: 32px !important;
    }


@media(max-width:1199px) {
    .spot .ship{
        margin-left: 33px !important;
    }
    .reefer .refer-h {
        padding: 0px;
      }
      .reefer .mat-form-field-infix{
        margin-left: 0px !important;
      }

      .mat-drawer-content {
        margin-left: 0px !important;
      }
}
@media(min-width:1199px) {
    .temp{
        padding: 0px;
    }
    /* .temp-h{
        padding: 0px;
    } */
}

@media(max-width:600px) {
    .spot .ship{
        margin-left: 0px !important;
    }
    .spot .ship-down {
        margin-left: 0px !important;
    }
    .temp{
        padding: 0px;
    }

    .spot .p-l-46 {
        padding-left: 0px !important;
    }
    .refer-spot .p-l-46 {
        padding-left: 15px !important;
      }

}

.reefer .mat-form-field-infix{
    margin-left: 15px;
  }

  @media(max-width:991px) and (min-width:768px){
      .align_items_baseline_md{
        align-items: baseline;
    }
  }


  @media(max-width:1300px) {
      .mat-drawer-content {
        margin-left: 0px !important;
      }
}




.mat-select-value {
    font-size: 13px !important;
    color: currentColor !important;
    font-family: 'Gotham-Book', 'Muli', sans-serif !important;
  }

  .mat-option-text {
    font-size: 13px !important;
  }


  .breakLabelToggle1 .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 30px !important;
    padding: 2px 3px !important;
  }

  .breakLabelToggle1 .mat-button-toggle-label-content {
    display: flex;
    line-height: 12px !important;
    white-space: normal !important;
    width: 90% !important;
    text-align: left !important;
    padding-top: 6px !important;
    font-size: 13px !important;
    align-items: center;
    margin-right: -10px !important;
  }

  .price{
    color: #12AFEC;
}
